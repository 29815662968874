





















































































































import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";

import api from "@/api";
import {
  AddressType,
  CityDto,
  ProvinceDto,
  WarehouseSelfLiftinPointCreateOrUpdateDto,
  WarehouseSelfLiftinPointDto,
} from "@/api/appService";
import { ElForm } from "element-ui/types/form";

@Component({
  name: "WarehouseSelfLiftinPointEdit",
})
export default class WarehouseSelfLiftinPointEdit extends Vue {
  @Ref() readonly dataForm!: ElForm;

  @Prop({ required: true })
  dataId!: number;

  @Prop({ required: true })
  warehouseId!: number;

  show = false;
  loading = true;
  form: WarehouseSelfLiftinPointCreateOrUpdateDto = {
    warehouseId: 0,
  };
  provinceList: ProvinceDto[] | undefined = [];
  citiesList: CityDto[] | undefined = [];
  defaultData: WarehouseSelfLiftinPointDto = {
    id: 0,
  };
  addressTypeArray: any = [
    { label: "仓库自提点", value: AddressType.WarehouseSelfLiftinPoint },
  ];

  created() {
    this.getProvinceList();
  }

  async getProvinceList() {
    await api.province.getAll({ maxResultCount: 65535 }).then((res) => {
      this.provinceList = res!.items;
    });
  }

  @Watch("form.provinceId")
  getCitiesList() {
    if (this.form.provinceId != null) {
      // console.log("provinceId=" + this.form.provinceId);
      api.city
        .getAllList({
          provinceId: this.form!.provinceId,
        })
        .then((res) => {
          // console.log("citiesList=" + this.citiesList);
          this.citiesList = res!;
        });
    }
  }

  save() {
    (this.dataForm as any).validate(async (valid: boolean) => {
      this.form.warehouseId = this.warehouseId;
      if (valid) {
        if (this.dataId) {
          api.warehouseSelfLiftinPoint
            .update({
              body: this.form,
            })
            .then(() => {
              this.show = false;
              this.$message.success((this as any).$l.getLanguageText("basic.updateSuccess").toString());
            });
        } else {
          api.warehouseSelfLiftinPoint
            .create({
              body: this.form,
            })
            .then(() => {
              this.show = false;
              this.$message.success((this as any).$l.getLanguageText("basic.createSuccess").toString());
            });
        }
      }
    });
  }

  @Watch("show")
  onShowChange(value: boolean) {
    if (value) {
      if (this.dataId) {
        this.loading = true;
        api.warehouseSelfLiftinPoint
          .get({ id: this.dataId })
          .then((res: WarehouseSelfLiftinPointCreateOrUpdateDto) => {
            this.form = res!;
            console.log("form=" + this.form.warehouseId);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.form = { ...this.defaultData };
        this.loading = false;
      }
    } else {
      this.form = { ...this.defaultData };
    }
    this.$nextTick(() => {
      this.dataForm.clearValidate();
    });
  }

  linkRule = {
    contacts: [
      {
        required: true,
        message: (this as any).$l.getLanguageText("wareHouseLocation.tips.contacts"),
        trigger: "blur",
      },
    ],
    contactNumber: [
      {
        required: true,
        message: (this as any).$l.getLanguageText("wareHouseLocation.tips.contactNumber"),
        trigger: "blur",
      },
    ],
    contactEmailAddress: [
      {
        required: true,
        message: (this as any).$l.getLanguageText("wareHouseLocation.tips.contactEmailAddress"),
        trigger: "blur",
      },
    ],
    provinceId: [
      {
        required: true,
        message: (this as any).$l.getLanguageText("wareHouseLocation.tips.province"),
        trigger: "blur",
      },
    ],
    cityId: [
      {
        required: true,
        message: (this as any).$l.getLanguageText("wareHouseLocation.tips.city"),
        trigger: "blur",
      },
    ],
    address: [
      {
        required: true,
        message: (this as any).$l.getLanguageText("wareHouseLocation.tips.address"),
        trigger: "blur",
      },
    ],
  };

  get title() {
    if (this.dataId) {
      return (this as any).$l.getLanguageText("basic.edit").toString();
    } else {
      return (this as any).$l.getLanguageText("basic.create").toString();
    }
  }

  cancel() {
    this.show = false;
  }
}
