






























































































import api from "@/api/index"; //ABP API接口
import { Vue, Component } from "vue-property-decorator";

import { WarehouseDto, WarehouseSelfLiftinPointDto } from "@/api/appService";
import PagedTableView from "@/components/PagedTableView.vue";
import WareHouseEdit from "@/views/warehouse/warehouseLocation/edit.vue";
import LocationEdit from "@/views/warehouse/warehouseLocation/locationEdit.vue";
import WarehouseSelfLiftinPointEdit from "@/views/warehouse/warehouseLocation/selfLiftinPointsEdit.vue";
import PagedTableViewNoQuery from "@/components/PagedTableViewNoQuery.vue";

@Component({
  name: "WarehouseSelfLiftinPointList",
  components: {
    PagedTableViewNoQuery,
    WarehouseSelfLiftinPointEdit,
    LocationEdit,
    WareHouseEdit,
    PagedTableView,
  },
})
export default class WarehouseSelfLiftinPointList extends Vue {
  detail: WarehouseDto = {};
  selfLiftinPointId = 0;
  warehouseId = 0;
  queryForm = {
    warehouseId: 0,
  };

  created() {
    if (this.$route.query.id) {
      this.warehouseId = Number(this.$route.query.id);
      this.queryForm.warehouseId = this.warehouseId;
      api.wareHouse
        .get({
          id: this.warehouseId,
        })
        .then((res) => {
          this.detail = res!;
        });
    }
  }

  // 获取表数据
  fetchData(params: any) {
    return api.warehouseSelfLiftinPoint.getAll(params);
  }

  // 新建
  handleCreate() {
    this.selfLiftinPointId = 0;
    (this.$refs.editForm as any).show = true;
  }

  // 编辑
  handleEdit(index: number, row: WarehouseSelfLiftinPointDto) {
    // console.log("id=" + row!.id);
    this.selfLiftinPointId = row.id!;
    (this.$refs.editForm as any).show = true;
    (this.$refs.editForm as any).form = row;
  }

  // 删除
  handleDelete(index: number, row: WarehouseSelfLiftinPointDto) {
    this.$confirm(
      (this as any).$l.getLanguageText("basic.deleteTip").toString(),
      (this as any).$l.getLanguageText("basic.tip").toString()
    ).then(async () => {
      api.warehouseSelfLiftinPoint
        .delete({
          id: row.id,
        })
        .then(() => {
          this.$message({
            type: "success",
            message: (this as any).$l.getLanguageText("basic.deleteSuccess").toString(),
          });
        });
    });
  }
}
